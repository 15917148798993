.bgimg-2, .bgimg-3 {
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.bgimg-1 {
  background-image: url("../../../public/image_1.jpeg");
  min-height: 650px;
  position: relative;
  background-attachment: scroll;
  background-position: 50% 75%;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 20px 50px 15px black inset;
}
  
.bgimg-2 {
  background-image: url("../../../public/image_1.webp");
  min-height: 650px;
}
  
.bgimg-3 {
  background-image: url("../../../public/image_3.webp");
  background-position: top;
  min-height: 500px;
  padding: 10px 10px;
}

.pt-6 {
  padding-top: 10rem !important;
}

.pt-7 {
  padding-top: 12rem !important;
}

.carousel .carousel-indicators li {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 10px;
  text-indent: 0;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: #000000;  
  color: #000000; 
}
.carousel .carousel-indicators .active {
  width: 8px;
  height: 8px;
  margin: 10px;
  background-color: #A9A9A9;
  color: #A9A9A9;
}
  
  /* Turn off parallax scrolling for tablets and phones */
  @media only screen and (max-device-width: 1024px) {
    .bgimg-1, .bgimg-2, .bgimg-3 {
      background-attachment: scroll;
    }
  }