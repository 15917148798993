#location-info {
  color: lightgray;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

#location-info span {
  margin-right: 1rem;
}

.viewport-size {
  color: #f9f9f9;
}