/* // Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
  background: #82e0aa;
  color: white;
  border: 1px solid light-red;
}

/* // Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: dark-red;
  color: white;
}

/* // Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: orange;
  color: white;
}

/* // Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: brown;
}


.DateRangePicker {
  /* font-size: 12px !important; */
  border-radius: 0.25rem !important;
  border: 1px solid #ced4da;
  background-clip: padding-box;
  color: #495057;
  height: calc(2.25rem + 2px);
}

.DateRangePickerInput__withBorder {
  height: calc(2.25rem + 2px);
}

.DateInput_input {
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
}

.DateInput_input {
  height: calc(2.25rem + 2px);
}