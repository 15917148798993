/* .plan-grid-item .card-body {
  padding: 0px 14px 14px 14px !important;
}
*/

.plan-grid-item h1 {
  font-size: 1em;
  margin-bottom: 0; 
}

.plan-grid-item ul {
  list-style: none;
  margin: 0;
  padding-inline-start: 0;
}

.plan-grid-item li, .plan-grid-item a, .plan-grid-item span {
  font-size: 0.8em !important;
}

.plan-grid-item .card-controls button.right-button {
  padding-left: 0;
}

.plan-grid-item .card-controls button.left-button {
  padding-right: 0;
}