#video-container {
  max-width: 100% !important;
  margin: 0;
  padding: 0;
}

#video-container video {
  width: 100%;
}

#video-container .carousel-caption {
  top: 50%;
  transform: translateY(-50%);
  bottom: initial;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

#video-container h1 {
  font-size: 1.2rem;
}