.point-of-interest h1 {
  color: white !important;
}

.point-of-interest h2 {
  text-transform: uppercase;
  text-shadow: rgba(0,0,0,.33) 0 0 5px;
}

.link-shadow {
  text-shadow: rgba(0,0,0,.33) 0 0 5px;
}

