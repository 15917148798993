/* makes main-menu open on hover */
.menu-item:hover > .dropdown-menu {
  display: block;
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
}

/* makes sub-menu S open on hover */
.submenu-item:hover > .dropdown-menu {
  display: block;
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
}
